<template>
   <CTabs tabs class="nav-underline nav-underline-primary">
        <CTab active>
          <template slot="title">
            <CIcon name="cil-task" />&nbsp;Filtre
          </template>
          <CForm ref="form" style="height: 100vh; padding: 10px 10px 100px; overflow-y: scroll;">
            <CRow>
                <CCol col="6">
                    <CSelect
                        :value.sync="data.countryId"
                        :options="countries"
                        placeholder="Ülke Adı"
                        @change="getCities"
                        required
                    >
                        <template #prepend-content>
                            <CIcon name="cilTextSquare" />
                        </template>
                    </CSelect>
                </CCol>
                <CCol col="3" sm="2">
                    <CButton
                    type="submit"
                    size="sm"
                    color="primary"
                    @click="filter"
                    >
                        <CIcon name="cil-check-circle" /> Filtrele
                    </CButton>
                </CCol>
                <CCol col="3" sm="2">
                    <CButton
                    size="sm"
                    color="danger"
                    @click="clearFilters"
                    >
                        <CIcon name="cil-ban" /> Temizle
                    </CButton>
                </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                  :value.sync="data.cityId"
                  :options="cities"
                  placeholder="İl Adı"
                  required
                >
                    <template #prepend-content>
                        <CIcon name="cilTextSquare" />
                    </template>
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
              <CCol sm="6">
                <CSelect
                  :value.sync="data.shippingCompanyId"
                  :options="[
                    { label: 'Tümü', value: '' },
                    { label: 'Hepsijet', value: '1' },
                    { label: 'Packupp', value: '2' }
                  ]"
                  placeholder="Kargo Firması"
                >
                    <template #prepend-content>
                        <CIcon name="cilTextSquare" />
                    </template>
                </CSelect>
              </CCol>
            </CRow>
            <CRow>
                <CCol sm="6">
                    <CSelect
                        :value.sync="data.activeState"
                        :options="[
                            { label: 'Tümü', value: '' },
                            { label: 'Aktif', value: '1' },
                            { label: 'Pasif', value: '0' }
                        ]"
                        placeholder="Aktiflik Durumu"
                    >
                        <template #prepend-content>
                            <CIcon name="cilTextSquare" />
                        </template>
                    </CSelect>
                </CCol>
            </CRow>
          </CForm>
        </CTab>
    </CTabs>
</template>

<script>
   export default{
       name: "DistributionAreaFilter",
       props:{
           validated: Boolean,
           params: Object,
           actionType: String,
           filter: Function
       },
       computed:{
            countries() {
                let data = []
                this.$store.getters.allCountries.map(c => data.push({label: c.name, value: c.id}))
                return data
            },
            cities() {
                let data = []
                this.$store.getters.allCities.map(c => data.push({label: c.name, value: c.id}))
                return data
            },
       },
       watch:{
           params: function(val){
               this.data = {...val};
           },
       },
       data: function(){
           return{
               data: this.params ? {...this.params} : {countryId: 192},
           }
       },
       methods:{
            getCities: async function(){
                let params = {countryId: this.data.countryId}
                await this.$store.dispatch('allCities_list', params)
            },
            clearFilters: function(){
                this.data = {countryId: 192}
            }
       }
   }
</script>